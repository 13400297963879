import UserDAO from "../models/user/UserDAO";
import apiService from "./apiService";

class UserService {
  static UserDTO(content) {
    return content.map(item => new UserDAO().toObject(item));
  }

  static getUser() {
    return new Promise((resolve, reject) => {
      apiService
        .get("user")
        .then(response => {
          const users = this.UserDTO(response.data);
          users.forEach(user => {
            if (user.getMetadataType() === "Profile") {
              resolve(user);
            }
          });
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static getAllUsers(pageno, ipp, search) {
    const userProfileEndPoint = search ? "user/profiles?search_text=" : "user/profiles?pageno="
    const endpoint = search ? `${userProfileEndPoint}${search}` : `${userProfileEndPoint}${pageno}&ipp=${ipp}`;
    const formattedEndpoint = endpoint.replace(/ $/,"%20");

    return new Promise((resolve, reject) => {
      apiService
        .get(formattedEndpoint)
        .then(response => {
          const users = this.UserDTO(response.data.items ? response.data.items : response.data);
          const totalItems = response.data.totalItems;
          const u = [];
          users.forEach(user => {
            if (user.getMetadataType() === "Profile") {
              u.push(user);
            }
          });
          const fullResponse = { u, totalItems: totalItems };
          resolve(fullResponse);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static getAssignees() {
    return new Promise((resolve, reject) => {
      const assignees = [];
      this.getFullAdmin().then(users => {
        users.forEach(user => {
          assignees.push(user);
        });
      });
      this.getQuestionAdmins().then(users => {
        users.forEach(user => {
          assignees.push(user);
        });
      });
      this.getAdvisors().then(users => {
        users.forEach(user => {
          assignees.push(user);
        });
      });
      resolve(assignees);
    });
  }

  static getFullAdmin() {
    return new Promise((resolve, reject) => {
      apiService
        .get("user/profiles?permissionLevel=FullAdmin")
        .then(response => {
          const assignees = [];
          const users = this.UserDTO(response.data);
          users.forEach(user => {
            if (user.getMetadataType() === "Profile") {
              const profile = user.getUserData();
              assignees.push(profile);
            }
          });
          resolve(assignees);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static getQuestionAdmins() {
    return new Promise((resolve, reject) => {
      apiService
        .get("user/profiles?permissionLevel=QuestionAdmin")
        .then(response => {
          const assignees = [];
          const users = this.UserDTO(response.data);
          users.forEach(user => {
            if (user.getMetadataType() === "Profile") {
              const profile = user.getUserData();
              assignees.push(profile);
            }
          });
          resolve(assignees);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static getAdvisors() {
    return new Promise((resolve, reject) => {
      apiService
        .get("user/profiles?permissionLevel=Advisor")
        .then(response => {
          const assignees = [];
          const users = this.UserDTO(response.data);
          users.forEach(user => {
            if (user.getMetadataType() === "Profile") {
              const profile = user.getUserData();
              assignees.push(profile);
            }
          });
          resolve(assignees);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static updateUser(user) {
    return new Promise((resolve, reject) => {
      apiService
        .post("user", user)
        .then(response => {
          if (response.status === 201) {
            resolve(response.data);
          }
          reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

export default UserService;